import {
  Component,
  ContentChild,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, Observable, Subscription, tap } from 'rxjs';

import { ActiveProjectState } from '../../../../shared/active-project/models/active-project-state.model';
import { ActiveProjectActions } from '../../../../shared/active-project/store/active-project.actions';
import { LcasApiClientService } from '../../../../shared/api-client/services/lcas-api-client/lcas-api-client.service';
import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { EdgeDeviceState } from '../../../../shared/devices-commissioning/models/edge-device-state.model';
import { EdgeDeviceActions } from '../../../../shared/devices-commissioning/store/edge-device.actions';
import { FloorWithLocations } from '../../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';
import {
  LoadedStatus,
  LocationsState,
} from '../../location-store/models/location.mode';
import { LocationActions } from '../../location-store/store/location.actions';
import {
  selectFloorsAndLocationsLoadedStatus,
  selectFloorsWithLocations,
  selectIsLoading,
} from '../../location-store/store/location.reducers';
import { TreeItemWithId } from '../../models/tree-item-with-id.model';
import { MainDetailContainerService } from '../../services/main-detail-container.service';
import { ViewDataService } from '../../services/view-data.service';

@Component({
  selector: 'app-main-detail-container',
  templateUrl: './main-detail-container.component.html',
  providers: [ViewDataService],
})
export class MainDetailContainerComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'd-flex flex-column flex-grow-1';

  @ContentChild('masterActions') masterActionsTemplate!: TemplateRef<any>;

  @ContentChild('masterData') masterDataTemplate!: TemplateRef<any>;

  @ContentChild('detailActions') detailActionsTemplate!: TemplateRef<any>;

  @ContentChild('devicesDetails') devicesDetailsTemplate!: TemplateRef<any>;

  detailsActive: boolean = false;

  private subscription: Subscription = new Subscription();

  projectName$ = this.store.select(
    (store) => store.project.activeProject?.name,
  );

  locationTree$: Observable<TreeItemWithId[]> =
    this.viewDataService.getLocationTree();

  selectedLocation$: Observable<Location | undefined> =
    this.viewDataService.getSelectedLocation();

  viewData$: Observable<{
    treeItems: TreeItemWithId[];
    floorsWithLocations: FloorWithLocations[];
    isLoading: boolean;
    floorsWithLocationsLoadedStatus: LoadedStatus;
    selectedLocation?: Location;
  }> = combineLatest([
    this.locationTree$,
    this.store.select(selectFloorsWithLocations),
    this.store.select(selectIsLoading),
    this.store.select(selectFloorsAndLocationsLoadedStatus),
    this.selectedLocation$,
  ]).pipe(
    map(
      ([
        treeItems,
        floorsWithLocations,
        isLoading,
        floorsWithLocationsLoadedStatus,
        selectedLocation,
      ]) => ({
        treeItems,
        floorsWithLocations,
        isLoading,
        floorsWithLocationsLoadedStatus,
        selectedLocation,
      }),
    ),
  );

  constructor(
    private store: Store<{
      project: ActiveProjectState;
      edgeDeviceState: EdgeDeviceState;
      floorsAndLocations: LocationsState;
    }>,
    private viewDataService: ViewDataService,
    private lcasApiClientService: LcasApiClientService,
    private mainDetailContainerService: MainDetailContainerService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(LocationActions.initLoadFloorsAndLocations());
    this.store.dispatch(LocationActions.initLoadProducts());
    this.store.dispatch(ActiveProjectActions.initLoadDevicesCatalog());
    this.lcasApiClientService
      .getDevices({ type: ['SysXController'] })
      .pipe(
        first(),
        tap((edgeDevices) => {
          if (edgeDevices.length) {
            this.store.dispatch(
              EdgeDeviceActions.setEdgeDevice({
                edgeDevice: edgeDevices[0],
              }),
            );
          }
        }),
      )
      .subscribe();
    this.listenForDetailsActiveState();
  }

  ngOnDestroy(): void {
    this.store.dispatch(LocationActions.clearState());
    this.subscription.unsubscribe();
  }

  private listenForDetailsActiveState(): void {
    this.subscription.add(
      this.mainDetailContainerService.detailsActive$
        .pipe(
          tap((isActive) => {
            this.detailsActive = isActive;
          }),
        )
        .subscribe(),
    );
  }
}
