import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable()
export class UrlBuilderService {
  build(url: string, queryParams: Params): string {
    return `${url}${Object.entries(queryParams).reduce(
      (acc, [key, value], index) =>
        value === undefined || value === null
          ? acc
          : `${acc}${this.getQuerySeparator(index)}${key}=${value}`,
      '',
    )}`;
  }

  private getQuerySeparator(index: number): string {
    return index === 0 ? '?' : '&';
  }
}
